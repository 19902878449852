<template>
  <div @contextmenu="$emit('contextmenu', $event)">
    <div class="layer-component rounded" v-if="layer" ref="layerContainer">
      <div class="layer-component_left b-avatar badge-light-primary rounded handle">
        <!-- <feather-icon icon="LayersIcon" size="20" class="handle" /> -->
        <component v-if="layer.type === 'point' && layer.shape.shapeType === 'Circle'" :is="require('/static/images/layer-config/radio/shape-circle.svg').default" />
        <component v-if="layer.type === 'point' && layer.shape.shapeType === 'Triangle'" :is="require('/static/images/layer-config/radio/shape-triangle.svg').default" />
        <component v-if="layer.type === 'point' && layer.shape.shapeType === 'Square'" :is="require('/static/images/layer-config/radio/shape-square.svg').default" />
        <component v-if="layer.type === 'point' && layer.shape.shapeType === 'Spike'" :is="require('/static/images/layer-config/radio/shape-spike.svg').default" />
        <component v-if="layer.type === 'image'" :is="require('/static/images/layer-config/radio/type-image.svg').default" />
        <component v-if="layer.type === 'typhoon'" :is="require('/static/images/layer-config/radio/type-typhoon.svg').default" />
        <component v-if="layer.type === 'multi'" :is="require('/static/images/layer-config/radio/type-multi.svg').default" />
        <component v-if="layer.type === 'netcdf'" :is="require('/static/images/layer-config/radio/type-netcdf.svg').default" />
      </div>
      <div class="layer-component_right">
        <div class="layer-component_right_top">
          <div class="layer-component_right_top_name-layer" :title="layer.name" :class="{ 'layer-netcdf': layer.type === 'netcdf' }">
            {{ layer.name }}
          </div>
          <div class="layer-component_right_top_action layer-options-menu-popper">
            <div :title="!layerVisibleM0 ? $t('show_layer') : $t('hide_layer')" class="layer-component_right_top_action_icon bg-light-primary rounded" @click.prevent.stop="visibleLayerItem(layer)">
              <feather-icon :icon="layerVisibleM0 ? 'EyeIcon' : 'EyeOffIcon'" size="14" />
            </div>
            <popper
              :class="`layer-popper-items-${layer.id}`"
              trigger="clickToOpen"
              :ref="`refPopper-${layer.id}`"
              :options="{
                placement: 'bottom-end',
                modifiers: { offset: { offset: '6px,5px' } },
              }"
            >
              <div class="popper options-layers-menu">
                <button :title="$t('inspect')" class="d-flex align-items-center options-layers-menu-items" @click.prevent="clInspectLayer(layer)">
                  <span class="menu-icon d-flex align-items-center options-layers-menu-items-icon">
                    <div :title="$t('inspect')" class="layer-component_right_top_action_icon bg-light-primary rounded">
                      <feather-icon icon="MaximizeIcon" size="14" />
                    </div>
                  </span>
                  <span>{{ $t('inspect') }}</span>
                </button>
                <!-- <button :title="!layer.visible[0] ? $t('show_layer') : $t('hide_layer')" class="d-flex align-items-center options-layers-menu-items" @click.prevent="visibleLayerItem(layer)">
                  <span class="menu-icon d-flex align-items-center options-layers-menu-items-icon">
                    <div :title="!layer.visible[0] ? $t('show_layer') : $t('hide_layer')" class="layer-component_right_top_action_icon bg-light-primary rounded">
                      <feather-icon :icon="layer.visible[0] ? 'EyeIcon' : 'EyeOffIcon'" size="14" />
                    </div>
                  </span>
                  <span>{{ !layer.visible[0] ? $t('show_layer') : $t('hide_layer') }}</span>
                </button> -->
                <button :title="$t('config_layer')" class="d-flex align-items-center options-layers-menu-items" @click.prevent="showMoalConfigLayer()">
                  <span class="menu-icon d-flex align-items-center options-layers-menu-items-icon">
                    <div :title="$t('config_layer')" class="layer-component_right_top_action_icon bg-light-primary rounded">
                      <feather-icon icon="ToolIcon" size="14" />
                    </div>
                  </span>
                  <span>{{ $t('config_layer') }}</span>
                </button>
                <button :title="$t('delete_layer')" class="d-flex align-items-center options-layers-menu-items" @click.prevent="openLayerItemDeleteConfirm()">
                  <span class="menu-icon d-flex align-items-center options-layers-menu-items-icon">
                    <div :title="$t('delete_layer')" class="layer-component_right_top_action_icon bg-light-primary rounded">
                      <feather-icon icon="Trash2Icon" size="14" />
                    </div>
                  </span>
                  <span>{{ $t('delete_layer') }}</span>
                </button>
              </div>

              <div slot="reference" :title="$t('Options')" class="layer-component_right_top_action_icon bg-light-primary rounded cursor-pointer">
                <feather-icon icon="MoreHorizontalIcon" size="14" />
              </div>
            </popper>
          </div>
        </div>
        <!-- <div class="layer-component_right_top_action">
          <div :title="$t('inspect')" class="layer-component_right_top_action_icon bg-light-primary rounded" @click.prevent="$store.commit('layer/INSPECT_LAYER', { layerId: layer.id })">
            <feather-icon icon="MaximizeIcon" size="14" />
          </div>
          <div :title="layer.visible[0] ? $t('show_layer') : $t('hide_layer')" class="layer-component_right_top_action_icon bg-light-primary rounded" @click.prevent="visibleLayerItem(layer)">
            <feather-icon :icon="layer.visible[0] ? 'EyeIcon' : 'EyeOffIcon'" size="14" />
          </div>
          <div :title="$t('config_layer')" class="layer-component_right_top_action_icon bg-light-primary rounded" @click.prevent="showMoalConfigLayer()">
            <feather-icon icon="ToolIcon" size="14" />
          </div>
          <div :title="$t('delete_layer')" class="layer-component_right_top_action_icon bg-light-primary rounded" @click.prevent="openLayerItemDeleteConfirm()">
            <feather-icon icon="Trash2Icon" size="14" />
          </div>
        </div> -->
        <div class="layer-component_right_bottom">
          <div v-show="layer.item">{{ layer.item }}</div>
          <div v-if="layer.type === 'netcdf'" v-show="layer.item">,</div>
          <div v-if="layer.type === 'netcdf'" v-show="layer.item" style="margin-left: 3px">{{ $t('cache') + ' ' + cacheItemLayer }}%</div>
        </div>
      </div>
    </div>
    <LayerConfig ref="modalConfigLayer" :layer="layer" />
    <LayerItemDeleteConfirm ref="layerItemDeleteConfirm" :layer="layer" />
  </div>
</template>

<script>
const _ = require('lodash');
import LayerConfig from '@/views/ecoplot-desktops/tabs/map/control-panel/layers/LayerConfig.vue';
import LayerItemDeleteConfirm from '@/views/ecoplot-desktops/tabs/map/control-panel/layers/tools/LayerItemDeleteConfirm.vue';
import { ACTION_LOG } from '@/constants/actionLog';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  components: { LayerConfig, LayerItemDeleteConfirm, Popper },
  props: ['layer', 'layerMove'],
  data() {
    return {
      // Copy paste layer
      showContextmenuLayer: false,
      styleContextMenu: '',
      layerVisibleM0: this.layer.visible && this.layer.visible[0] ? true : false,
    };
  },
  mounted() {
    document.addEventListener('mousedown', (event) => {
      setTimeout(() => {
        try {
          let element = document.querySelector(`.layer-popper-items-${this.layer.id}`);
          if (!element.contains(event.target)) {
            this.$refs[`refPopper-${this.layer.id}`].showPopper = false;
          }
        } catch {}
      }, 500);
    });
  },
  computed: {
    layers() {
      return this.$store.state.layer.layers;
    },
    contextMenuCopy() {
      return this.$store.state.map.contextMenu.layerCopy;
    },
    disablePaste() {
      return !Object.keys(this.contextMenuCopy).length || this.contextMenuCopy == {} || this.contextMenuCopy.type !== this.layer.type;
    },
    disableCopy() {
      return !this.layer.dataImage && !this.layer.dataMulti && !this.layer.dataNetcdf && !this.layer.dataPoint && !this.layer.dataTimeseries && !this.layer.dataTyphoon;
    },
    cacheItemLayer() {
      try {
        const datesLength = _.union(this.$db[this.layer.dataNetcdf].timeseries[this.layer.item].dates).length;
        const cacheLength = _.union(this.layer.netcdf.netcdfCache).length;
        return cacheLength <= datesLength ? Math.round((cacheLength / datesLength) * 100) : 100;
      } catch {
        return 0;
      }
    },
  },
  watch: {
    layer: {
      deep: true,
      handler() {
        try {
          this.layerVisibleM0 = this.layer.visible[0];
        } catch {}
      },
    },
  },
  methods: {
    clInspectLayer(layer) {
      this.clickMapHidenPapper();
      this.$store.commit('layer/INSPECT_LAYER', { layerId: layer.id });
    },
    clickMapHidenPapper() {
      try {
        let mapListEle = document.getElementById(`map-list`);
        mapListEle.click();
      } catch {}
    },
    showMoalConfigLayer() {
      this.clickMapHidenPapper();
      this.$refs.modalConfigLayer.open();
    },
    openLayerItemDeleteConfirm() {
      this.clickMapHidenPapper();
      this.$refs.layerItemDeleteConfirm.open();
    },
    visibleLayerItem() {
      this.layerVisibleM0 = !this.layerVisibleM0;
      let layer = _.cloneDeep(this.layer);
      let messageTitle = !layer.visible[0] ? 'show_layer' : 'hide_layer';
      let messageLog = `: '${layer.name}'`;
      let message = this.$t(messageTitle) + messageLog;
      this.log.info({ message, id: ACTION_LOG[18].id, messageTitle, messageLog });
      this.$store.commit('layer/TOGGLE_VISIBLE', { layerId: layer.id, mapIndex: 0 });
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
/* style new cho layer */
.layer-component {
  margin-top: 4px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(57, 123, 255, 0.12);
  &_left {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &_right {
    flex: 1;
    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &_icon {
          margin-left: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4.5px;
          cursor: pointer;
          &.aciton-play {
            border-radius: 50%;
          }
          .feather-trash-2 {
            color: #ea5455;
          }
        }
      }
      &_name-layer {
        color: inherit;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
        &.layer-netcdf {
          max-width: 100px;
        }
      }
    }
    &_bottom {
      font-size: 12px;
      color: #397bff;
      display: flex;
      align-items: center;
      height: 17px;
      min-width: 1px;
    }
  }
}
.item-group {
  & > .item-container {
    .layer-component_right_top_name-layer {
      max-width: 186px;
    }
  }
}
.options-layers-menu {
  padding: 2px 0px;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 180px;
  min-width: 154px;
  border-radius: 2px;
  transition: none;
  background: #fff;
  color: #4f4a5f;
  font-size: 13px;
  button {
    border: none;
    outline: none;
    &:focus {
      border: none;
      outline: none;
    }
  }
}
.options-layers-menu-items {
  height: 30px;
  max-width: 180px;
  min-width: 140px;
  text-align: left;
  border: none;
  width: 100%;
  background: inherit;
  color: inherit;
  &-icon {
    margin-right: 8px;
  }
  &:hover {
    background: rgba(57, 123, 255, 0.12);
  }
}
.dark-layout {
  .options-layers-menu {
    color: #b4b7bd;
    background-color: #161d31;
  }
  .options-layers-menu-items {
    &:hover {
      background: rgb(31 37 47 / 12%);
      color: #fff;
    }
  }
  .layer-options-menu-popper {
    .popper__arrow {
      border-color: transparent transparent #161d31 transparent !important;
    }
  }
}
</style>
