<template>
  <div class="timeseries-chart-container" :style="{ borderColor: isSelecting ? source.customData.lineColor : 'transparent' }">
    <div class="timeseries-chart-name" :style="{ color: isSelecting ? source.customData.lineColor : chartTextColor, fontFamily: chartFontFamily, fontSize: chartTextSize + 'px' }" @click="clickChart(source.customName, source.customUid)">{{ source.customName }}</div>
    <div class="timeseries-chart-item" ref="chart"></div>
  </div>
</template>

<script>
import MathMixin from '@/mixins/MathMixin.js'
import Plotly from 'plotly.js-dist'
import { locale } from '@/scripts/plotly/locale.js'
const _ = require('lodash')

const LAYOUT = {
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
  showlegend: false,
  margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
  xaxis: { visible: false },
  yaxis: { visible: false },
  hovermode: 'x',
}

const CONFIG = {
  displayModeBar: false,
  responsive: true,
}

export default {
  props: ['index', 'source', 'layerSelected', 'hovering', 'date', 'clicking', 'globalRange', 'blockZoomAll'],
  components: { locale },
  mixins: [MathMixin],
  mounted() {
    'undefined' == typeof Plotly ? ((window.PlotlyLocales = window.PlotlyLocales || []), window.PlotlyLocales.push(locale)) : Plotly.register(locale)
    this.updateChart()
    // listen to div resize => chart resize
    new ResizeObserver(() => {
      if (document.getElementsByClassName('timeseries-chart-item')[0] && this.$refs.chart && this.$refs.chart.style.display !== 'none') Plotly.Plots.resize(this.$refs.chart)
    }).observe(this.$refs.chart)
  },
  data() {
    return {
      hoveringTrace: null,
    }
  },
  computed: {
    isSelecting() {
      let isSelect = null
      if (this.clicking) {
        for (let i = 0; i < this.clicking.length; i++) {
          if (this.clicking[i] && this.clicking[i].layerId === this.layerSelected && this.clicking[i].clickId === this.source.customName && this.clicking[i].clickUid === this.source.customUid) {
            isSelect = this.clicking[i]
            break
          }
        }
      }
      return isSelect
    },
    chartFontFamily() {
      return this.$store.state.settings.chartFontFamily
    },
    chartTextColor() {
      return this.$store.state.settings.chartTextColor
    },
    chartTextSize() {
      return this.$store.state.settings.chartTextSize
    },
    layers() {
      return this.$store.state.layer.layers
    },
    layer() {
      if (!this.layerSelected) return null
      let layer = this.layers.find((l) => l.id === this.layerSelected)
      return layer
    },
    localRange() {
      try {
        if (this.source.y.length) {
          let sourceFilerNA = this.source.y.filter((bt) => !this.isNaValue(bt));
          let min = Math.min(...sourceFilerNA);
          let max = Math.max(...sourceFilerNA);
          return [Number(min), Number(max)];
        }
      } catch {}
      return;
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    locale() {
      this.updateChart()
    },
    blockZoomAll() {
      if (this.blockZoomAll) {
        this.$root.arrayTimeSeries.forEach((div) => {
          div.on('plotly_relayout', (ed) => {
            if (this.blockZoomAll) {
              if (Object.entries(ed).length === 0) return
              this.$root.arrayEleTimeSeries.forEach((div, i) => {
                const x = div.layout.xaxis
                if (ed['xaxis.autorange'] && x.autorange) return
                if (x.range[0] != ed['xaxis.range[0]'] || x.range[1] != ed['xaxis.range[1]']) {
                  const update = {
                    'xaxis.range[0]': ed['xaxis.range[0]'],
                    'xaxis.range[1]': ed['xaxis.range[1]'],
                    'xaxis.autorange': ed['xaxis.autorange'],
                  }
                  Plotly.relayout(div, update)
                }
              })
            }
          })
        })
      }
    },
    source: {
      deep: true,
      handler() {
        this.updateChart(true)
      },
    },
    hovering(hovering) {
      Plotly.Fx.hover(this.$refs.chart, [])

      let dateIndex = this.source.x.indexOf(this.date[0])

      if (!hovering || hovering.source !== 'map' || hovering.layerId !== this.layerSelected || hovering.hoverId !== this.source.customName || hovering.hoverUid !== this.source.customUid || dateIndex === -1) return

      Plotly.Fx.hover(this.$refs.chart, [{ curveNumber: 0, pointNumber: dateIndex }])
    },
    date() {
      if (this.source.x.length) {
        Plotly.relayout(this.$refs.chart, {
          shapes: [
            { type: 'rect', xref: 'x', yref: 'paper', x0: this.date[0], y0: 0, x1: this.source.x[this.source.x.length - 1], y1: 1, fillcolor: this.source.customData.backgroundColor, opacity: 0.5, line: { width: 0 } },
            { type: 'line', xref: 'x', yref: 'paper', x0: this.date[0], y0: 0, x1: this.date[0], y1: 1, line: { color: this.source.customData.lineColor, width: 2 } },
          ],
        })
      }
    },
    hoveringTrace(hoveringTrace) {
      if (hoveringTrace) {
        let [id, uid] = hoveringTrace.split(' - ')
        for (let i = 0; i < 2; i++) {
          const storeData = {
            data: { layerId: this.layerSelected, hoverId: id, hoverUid: uid === 'undefined' ? undefined : uid, source: 'sidebar' },
            mapIndex: i,
          }
          this.$store.commit('map/SET_HOVERING', storeData)
        }
      } else {
        for (let i = 0; i < 2; i++) {
          const storeData = {
            data: null,
            mapIndex: i,
          }
          this.$store.commit('map/SET_HOVERING', storeData)
        }
      }
    },
  },
  methods: {
    drawRelayout() {
      if (this.blockZoomAll) {
        this.$root.arrayTimeSeries.forEach((div) => {
          div.on('plotly_relayout', (ed) => {
            if (this.blockZoomAll) {
              if (Object.entries(ed).length === 0) return
              this.$root.arrayEleTimeSeries.forEach((div, i) => {
                let x = div.layout.xaxis
                if (ed['xaxis.autorange'] && x.autorange) return
                if (x.range[0] != ed['xaxis.range[0]'] || x.range[1] != ed['xaxis.range[1]']) {
                  var update = {
                    'xaxis.range[0]': ed['xaxis.range[0]'],
                    'xaxis.range[1]': ed['xaxis.range[1]'],
                    'xaxis.autorange': ed['xaxis.autorange'],
                  }
                  Plotly.relayout(div, update)
                }
              })
            }
          })
        })
      }
    },
    clickChart(id, uid) {
      let multiShow = false
      multiShow = this.layer.popup.multiShow
      //SET CLICKING FOR 2 MAP VIEW
      for (let i = 0; i < 2; i++) {
        const storeData = {
          data: { layerId: this.layerSelected, clickId: id, clickUid: uid, source: 'sidebar', multiShow: multiShow, pin: true },
          mapIndex: i,
        }
        this.$store.commit('map/SET_CLICKING', storeData)
      }
    },
    updateChart(isUpdate = false) {
      let _config = CONFIG
      _config.locale = this.locale
      const data = [{ ...this.source, line: { color: this.source.customData.chartColor } }]
      const layout = _.merge({}, LAYOUT, { yaxis: { range: this.source.customData.yAxisRange === 'same' ? this.globalRange : this.localRange } })
      if (isUpdate) {
        Plotly.react(this.$refs.chart, data, layout, _config).then(() => {
          this.updatePlotTimeIndex()
        })
      } else {
        Plotly.newPlot(this.$refs.chart, data, layout, _config).then(() => {
          this.updatePlotTimeIndex()
        })
      }
    },
    updatePlotTimeIndex() {
      this.$refs.chart.on('plotly_hover', (data) => {
        this.hoveringTrace = data.points[0].data.customName + ' - ' + data.points[0].data.customUid
      })
      this.$refs.chart.on('plotly_unhover', (data) => {
        this.hoveringTrace = null
      })

      if (this.source.x.length) {
        Plotly.relayout(this.$refs.chart, {
          shapes: [
            { type: 'rect', xref: 'x', yref: 'paper', x0: this.date[0], y0: 0, x1: this.source.x[this.source.x.length - 1], y1: 1, fillcolor: this.source.customData.backgroundColor, opacity: 0.5, line: { width: 0 } },
            { type: 'line', xref: 'x', yref: 'paper', x0: this.date[0], y0: 0, x1: this.date[0], y1: 1, line: { color: this.source.customData.lineColor, width: 2 } },
          ],
        })
      }

      this.$root.arrayTimeSeries = document.querySelectorAll('.timeseries-chart-item.js-plotly-plot')
      this.$root.arrayEleTimeSeries = document.querySelectorAll('.timeseries-chart-item.js-plotly-plot')
      if (this.$root.arrayTimeSeries) {
        this.drawRelayout()
      }
    },
  },
  beforeDestroy() {
    Plotly.purge(this.$refs.chart)
  },
}
</script>

<style scoped>
.timeseries-chart-container {
  border: 2px dashed;
}
.timeseries-chart-name {
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
}
.timeseries-chart-item {
  height: 58px;
}
</style>

<style>
.timeseries-chart-item .axistext {
  display: none;
}
</style>
