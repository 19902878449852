<template>
  <div>
    <b-modal size="xl" hide-footer centered ref="filesImport" :body-class="'custom-body tools-body'" modal-class="mbtsl-files-import files-import-modal">
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between flex-grow-1 modal-title">2. {{ $t('Time Series Files Import') }}</div>
        <button type="button" class="close" :title="$t('close')" @click="close">&times;</button>
      </template>
      <div class="tools-body mbtsl-files-import-content">
        <div class="d-flex h-100">
          <div class="file-manager">
            <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 1rem">
              <h3 class="mb-0" style="line-height: 30px">{{ $t('locations_mb') }}</h3>
              <b-button variant="flat-primary" size="sm" @click="resetAllDataTable" :title="$t('Reset all data table')" style="padding: 7px; background-color: rgba(40, 199, 111, 0.12); margin-right: 5px">
                <feather-icon icon="RefreshCwIcon" />
              </b-button>

              <!-- <b-button variant="flat-success" size="sm" @click="openFile"
                style="padding: 7px; background-color: rgba(40, 199, 111, 0.12); margin-right: 5px">
                <feather-icon icon="UploadIcon" />
              </b-button>
              <input type="file" ref="file" style="display: none" @change="addFile" accept=".csv" /> -->
              <!-- <button @click="initHeader">clicktesst</button> -->
            </div>
            <div class="file-container custom-scrollbar">
              <Draggable v-model="locations" handle=".handle" :animation="200" @start="drag = true" @end="drag = false">
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                  <div :class="`file-item bg-light-${location.variant ? location.variant : 'primary'}`" v-for="(location, index) in locations" :key="location.id">
                    <b-media no-body class="align-items-center file-media">
                      <b-media-aside class="handle" style="margin-right: 10px; cursor: grab">
                        <b-avatar rounded size="42" :variant="`light-${location.variant ? location.variant : 'primary'}`">
                          <feather-icon size="18" icon="MenuIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body style="text-align: left" @dblclick="clInput" :title="`double click and edit name`">
                        <input type="text" :value="location.name ? location.name : ``" class="ip-location-name" :title="location.name ? location.name : ``" disabled @blur="blurInput($event, location)" :placeholder="location.name" />
                      </b-media-body>
                    </b-media>
                    <b-badge pill @click.stop="resetDataLocation(location)" :title="$t('Reset data', { location: location.name ? location.name : `location` })" :variant="`light-${location.variant ? location.variant : 'primary'}`" style="margin-right: 5px; cursor: pointer">
                      <feather-icon size="18" icon="RefreshCwIcon" />
                    </b-badge>
                    <b-badge :title="$t('Upload data')" pill :variant="`light-${location.variant ? location.variant : 'primary'}`" style="margin-right: 10px; cursor: pointer" @click.stop="uploadDataLocation(location, index)">
                      <feather-icon size="18" icon="UploadIcon" />
                    </b-badge>
                  </div>
                </transition-group>
              </Draggable>
            </div>
          </div>
          <div style="flex-grow: 1; min-width: 0">
            <h3 class="" style="margin-bottom: 1rem">{{ $t('Data Table') }}</h3>
            <DatatableBody :modalType="null" :action="null" :rowsData="null" :filteredIndex="null" ref="datatableBody" style="margin-top: 18px" :columns="columns" :updateDatatable="updateDatatable" :dsType="`timeseries`" @getData="getDataTable" :itemFilterLength="itemFilterLength" :filtered="filteredData" :itemLength="itemLength" @edit-header="editHeader" />
          </div>
        </div>
      </div>
      <excelModal ref="excelModal" :dataExcelModal="dataExcelModal" @dataCsvExcel="getDataCsvExcel" />
      <createLayerModal ref="createLayerModal" :dataCreateLayerModal="dataCreateLayerModal" @closeFromCreateLayer="close" />
      <br />
      <div class="text-end text-right mt-1">
        <b-button variant="light" size="xl" class="mr-1" @click.stop="close"> {{ $t('Back') }} </b-button>
        <b-button variant="success" size="xl" class="mr-1" :disabled="!isNext" @click.stop="nextModal"> {{ $t('Next') }} </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
const _ = require('lodash')
const dayjs = require('dayjs')
import Papa from 'papaparse'
import Draggable from 'vuedraggable'
import FileMixin from '@/mixins/FileMixin.js'
import DatatableBody from './DatatableBody.vue'
import locationSelect from './locationSelect.vue'
import excelModal from './excelModal.vue'
import createLayerModal from './createLayerModal.vue'
// import TimeseriesSelect from '@/views/ecoplot-desktops/tabs/map/control-panel/datasources/modal/TimeseriesSelect.vue';

import { v4 as uuidv4 } from 'uuid'
import { convertNumber } from '@/utilities/NumberUtility.js'
import { ACTION_LOG } from '@/constants/actionLog'
import { ALL_FORMAT_DATE } from '@/constants/formatDate.js'
import { number } from 'mathjs'
export default {
  props: [],
  mixins: [FileMixin],
  components: { DatatableBody, locationSelect, Draggable, excelModal, createLayerModal },
  data() {
    return {
      drag: false,
      files: [],
      rows: [],
      columns: [],
      rowsData: [],
      columnsData: [],
      filename: '',
      selected: null,

      itemFilterLength: -1,
      itemLength: 0,
      filteredData: [],
      dataFilterStore: [],
      updateDatatable: 0,
      datasourceType: '',

      locations: [],
      headerTemp: [],
      dataExcelModal: {},
      dataCreateLayerModal: {},
    }
  },
  mounted() {
    this.rows = []
    this.updatedDataLocation()
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    saveable() {
      if (!this.files.length) return false

      for (let i = 0; i < this.columns.length; i++) {
        if (!this.columns[i].label[0]) return false
        if (!this.columns[i].label[2]) return false
      }

      if (!this.filename) return false

      return true
    },
    datasourceTools() {
      return this.$store.state.map.datasourceTools
    },
    mapBasedLocations() {
      return this.$store.state.mapBasedTimeseriesLoader.locations
    },
    isNext() {
      let a = `${this.cloumns}`
      if (!this.locations || !this.locations.length || !this.filteredData || !this.filteredData.length) {
        return false
      }
      if (this.columns && this.columns.length > 1) {
        let IdxID = this.columns[0].label.indexOf('ID')
        let IdxITEM = this.columns[0].label.indexOf('ITEM')
        if (IdxID > -1 && IdxID > -1) {
          for (let i = 1; i < this.columns.length; i++) {
            if (this.columns[i].label[IdxID] == null || this.columns[i].label[IdxID] == '' || this.columns[i].label[IdxITEM] == null || this.columns[i].label[IdxITEM] == '') {
              return false
            }
          }
          return true
        } else {
          return true
        }
      } else {
        return true
      }
    },
  },
  watch: {
    mapBasedLocations: {
      deep: true,
      handler() {
        this.locations = _.cloneDeep(this.mapBasedLocations)
      },
    },
    files(files, oldFiles) {
      if (files.length > oldFiles.length) {
        let fileToAdd = files.find((f) => !oldFiles.some((o) => o.id === f.id))
        // add column
        if (this.columns.length === 0) {
          this.columns.push({ label: ['ID', 'GROUP', 'ITEM', 'UNIT'], field: 'date' })
        }
        let columns = []
        let placeholder = ['enter_location', 'enter_group', 'enter_item', 'enter_unit']
        const LOCATION_IDX = fileToAdd.header.findIndex((c) => c[0] === 'ID')
        const GROUP_IDX = fileToAdd.header.findIndex((c) => c[0] === 'GROUP')
        const ITEM_IDX = fileToAdd.header.findIndex((c) => c[0] === 'ITEM')
        const UNIT_IDX = fileToAdd.header.findIndex((c) => c[0] === 'UNIT')

        for (let i = 1; i < fileToAdd.header[0].length; i++) {
          columns.push({ label: [fileToAdd.header[LOCATION_IDX][i], fileToAdd.header[GROUP_IDX][i], fileToAdd.header[ITEM_IDX][i], fileToAdd.header[UNIT_IDX][i]], field: `${fileToAdd.id}-${i}`, type: 'number', editable: true, placeholder })
        }
        let oldColumns = this.columns.filter((c) => c.field !== 'date')
        this.columns = [...this.columns, ...columns]

        // add row
        let oldRows = _.cloneDeep(this.rows)

        let newRows = fileToAdd.body.map((r) => {
          let data = { date: r[0] }
          columns.forEach((column, index) => {
            data[column.field] = r[index + 1]
          })
          return data
        })

        for (let i = 0; i < oldRows.length; i++) {
          let data = newRows.find((r) => r.date === oldRows[i].date)
          columns.forEach((column) => {
            oldRows[i][column.field] = data ? data[column.field] : null
          })
        }

        let rowToAdd = _.differenceBy(newRows, oldRows, (el) => el.date)
        for (let i = 0; i < rowToAdd.length; i++) {
          oldColumns.forEach((column) => {
            rowToAdd[i][column.field] = null
          })
        }

        this.rows = _.sortBy([...oldRows, ...rowToAdd], ['date'])
      } else if (oldFiles.length > files.length) {
        let fileToDelete = oldFiles.find((o) => !files.some((f) => f.id === o.id))

        // delete column
        this.columns = this.columns.filter((c) => !c.field.startsWith(fileToDelete.id))
        if (this.columns.length === 1) {
          this.columns = []
        }

        // delete row
        let rows = []
        for (let i = 0; i < this.rows.length; i++) {
          let data = {}
          this.columns.forEach((column) => {
            data[column.field] = this.rows[i][column.field]
          })
          rows.push(data)
        }

        let dateToKeep = []
        files.forEach((file) => {
          dateToKeep.push(...file.body.map((r) => ({ date: r[0] })))
        })

        this.rows = _.intersectionBy(rows, dateToKeep, 'date')
      } else {
        if (!this.columns.length) return

        // sort column
        let columns = [{ label: ['ID', 'GROUP', 'ITEM', 'UNIT'], field: 'date' }]
        this.files.forEach((file) => {
          this.columns.forEach((column) => {
            if (column.field.startsWith(file.id)) {
              columns.push(column)
            }
          })
        })
        this.columns = columns
      }
      this.updateDatatable++
      this.itemLength = this.rows.length
    },
    async selected(selected) {
      if (!selected) return

      let datasource = this.datasources.find((d) => d.id === selected)

      if (!datasource) return

      let header = [['ID'], ['GROUP'], ['ITEM'], ['UNIT']]
      let body
      let dataTmp = await this.selectAll(datasource.id)
      let convertData = {}
      convertData['dates'] = dataTmp['dates']
      for (const key in this.$db[datasource.id].columns) {
        let [location, item] = key.split('*')
        header[0].push(location)
        header[1].push(this.$db[datasource.id].columns[key].autoGroup)
        header[2].push(item)
        header[3].push(this.$db[datasource.id].columns[key].unit)
        convertData[`${location}-${item}`] = dataTmp[`${location}-${item}`]
      }
      body = this.transpose(Object.values(convertData))
      this.files = [...this.files, { id: datasource.id, name: datasource.name, header, body, variant: 'primary' }]
    },
    datasourceTools() {
      this.resetState()
    },
    filteredData() {},
    columns: {
      deep: true,
      handler() {
        if (this.columns && this.columns.length > 1) {
          let IdxID = this.columns[0].label.indexOf('ID')
          let IdxITEM = this.columns[0].label.indexOf('ITEM')
          if (IdxID > -1 && IdxID > -1) {
            for (let i = 1; i < this.columns.length; i++) {
              if (this.columns[i].label[IdxID] == null || this.columns[i].label[IdxID] == '' || this.columns[i].label[IdxITEM] == null || this.columns[i].label[IdxITEM] == '') {
                return false
              }
            }
            return true
          } else {
            return true
          }
        } else {
          return true
        }
      },
    },
    locations: {
      handler() {
        this.updatedDataLocation()
      },
    },
  },
  methods: {
    nextModal() {
      setTimeout(() => {
        if (!this.nextModal) return
        this.dataCreateLayerModal = {
          id: uuidv4(),
          locations: this.locations,
          columns: this.columns,
          rows: this.rows,
        }
        this.$refs.createLayerModal.open()
      }, 200)
    },
    resetDataLocation(location) {
      try {
        this.$mapBasedTimeseriesLoader[location.id] = {}
        this.filteredData = []
        this.dataFilterStore = []
        this.updateDatatable++
        this.updatedDataLocation()
      } catch {}
    },
    resetAllDataTable() {
      try {
        for (let i = 0; i < this.locations.length; i++) {
          this.$mapBasedTimeseriesLoader[this.locations[i].id] = {}
        }
        this.rows = []
        this.filteredData = []
        this.dataFilterStore = []
        this.itemFilterLength = 0
        this.itemLength = 0
        this.updateDatatable++
        this.updatedDataLocation()
      } catch {}
    },
    isNumericString(str) {
      try {
        const num = _.toNumber(str)
        return !_.isNaN(num) && _.isFinite(num)
      } catch {}
      return false
    },
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => (row && row[columnIndex] != null ? row[columnIndex] : null)))
    },
    updatedDataLocation() {
      setTimeout(async () => {
        try {
          let header = [
            {
              field: 'date',
              label: ['ID', 'GROUP', 'ITEM', 'UNIT'],
            },
          ]
          let dataBody = []
          if (this.locations) {
            for (let i = 0; i < this.locations.length; i++) {
              try {
                let bodyTemp = []
                const dataOriginLocation = this.$mapBasedTimeseriesLoader[this.locations[i].id]
                if (dataOriginLocation.time && dataOriginLocation.values && dataOriginLocation.values.length) {
                  const timesOrigin = dataOriginLocation.time

                  const timesCut = []
                  let dataTime = []

                  for (let h = 0; h < timesOrigin.length; h++) {
                    let timeTemp = timesOrigin[h]
                    let timeTempSsValid = dayjs(timeTemp)
                    if (timeTempSsValid.isValid()) {
                      try {
                        timeTemp = timeTempSsValid.format('YYYY-MM-DD HH:mm:ss')
                      } catch {}
                      dataTime.push(timeTemp)
                    } else {
                      timesCut.push(h)
                    }
                  }
                  bodyTemp.push(dataTime)
                  let headerOrigin
                  try {
                    headerOrigin = dataOriginLocation.header
                  } catch {}
                  for (let k = 0; k < dataOriginLocation.values.length; k++) {
                    const valuesOrigin = dataOriginLocation.values[k]
                    let item = ``
                    try {
                      item = valuesOrigin.name
                    } catch {}
                    let dataValue = []
                    if (timesCut && timesCut.length) {
                      for (let h = 0; h < timesOrigin.length; h++) {
                        if (timesCut.indexOf(h) < 0) {
                          let valueTemp = valuesOrigin.data[h]
                          if (!this.isNumericString(valueTemp)) {
                            valueTemp = ''
                          }
                          dataValue.push(valueTemp)
                        }
                      }
                    } else {
                      dataValue = valuesOrigin.data
                    }

                    bodyTemp.push(dataValue)
                    let labelTemp
                    if (headerOrigin && headerOrigin[k]) {
                      labelTemp = headerOrigin[k].label
                    } else {
                      let itemTemp = ''
                      try {
                        if (dataOriginLocation.headerValue && dataOriginLocation.headerValue['ITEM'] && dataOriginLocation.headerValue['ITEM'][k] != null) {
                          itemTemp = dataOriginLocation.headerValue['ITEM'][k]
                        } else {
                          itemTemp = valuesOrigin.name
                        }
                      } catch {
                        itemTemp = valuesOrigin.name
                      } finally {
                      }
                      let groupTemp = ''
                      try {
                        groupTemp = dataOriginLocation.headerValue && dataOriginLocation.headerValue['GROUP'] ? dataOriginLocation.headerValue['GROUP'][k] : ``
                      } catch {}
                      let unitTemp = ''
                      try {
                        unitTemp = dataOriginLocation.headerValue && dataOriginLocation.headerValue['UNIT'] ? dataOriginLocation.headerValue['UNIT'][k] : ``
                      } catch {}
                      labelTemp = [`${this.locations[i].name}`, groupTemp, itemTemp, unitTemp]
                    }
                    header.push({
                      editable: true,
                      field: `${this.locations[i].id}-${this.locations[i].name}-${k}`,
                      idLocation: `${this.locations[i].id}`,
                      label: labelTemp,
                      placeholder: ['enter_location', 'enter_group', 'enter_item', 'enter_unit'],
                      type: 'number',
                    })
                  }
                  dataBody.push(bodyTemp)
                  this.locations[i].variant = 'success'
                } else {
                  this.locations[i].variant = null
                  dataBody.push([[], []])
                  header.push({
                    editable: true,
                    field: `${this.locations[i].id}-${this.locations[i].name}-${i}`,
                    idLocation: `${this.locations[i].id}`,
                    label: [`${this.locations[i].name}`, '', `ITEM1`, ''],
                    placeholder: ['enter_location', 'enter_group', 'enter_item', 'enter_unit'],
                    type: 'number',
                  })
                }
              } catch {}
            }
          }
          // let rows = uPlot.join(dataBody)
          let rows = this.mergeDateArrays(dataBody)
          let rowsTemp = _.cloneDeep(rows)
          let columTemp = []
          rowsTemp[0] = rowsTemp[0].sort((date1, date2) => {
            return dayjs(date1, 'YYYY-MM-DD HH:mm:ss') - dayjs(date2, 'YYYY-MM-DD HH:mm:ss')
          })

          if (rows && rows.length > 1 && rows[0].length) {
            for (let j = 0; j < rows[0].length; j++) {
              columTemp[j] = [rowsTemp[0][j]]
              let idx = rows[0].indexOf(rowsTemp[0][j])
              for (let i = 1; i < rows.length; i++) {
                rowsTemp[i][j] = !rows[i][idx] || idx < 0 ? null : rows[i][idx]
                columTemp[j].push(rowsTemp[i][j])
              }
            }
          }
          this.columns = header
          this.rowsData = rowsTemp
          this.columnsData = _.cloneDeep(this.columns)
          const filteredData = columTemp
          this.rows = filteredData
          this.filteredData = filteredData
          this.itemLength = filteredData.length
          this.itemFilterLength = this.itemLength
        } catch {
          this.columns = []
          this.rowsData = []
          this.columnsData = []
          this.rows = []
          this.filteredData = []
          this.itemLength = 0
          this.itemFilterLength = 0
        }
      }, 0)
    },
    getDataCsvExcel(data, location) {
      let dataTemp = data[location.id]
      dataTemp.id = location.id
      this.$mapBasedTimeseriesLoader[location.id] = dataTemp
      this.updatedDataLocation()
    },
    uploadDataLocation(location, index) {
      this.dataExcelModal = _.cloneDeep(location)
      this.dataExcelModal.index = _.cloneDeep(index)
      setTimeout(() => {
        this.$refs.excelModal.open()
      }, 150)
    },
    initHeader() {
      let headerTemp = [
        {
          field: 'date',
          label: ['ID', 'GROUP', 'ITEM', 'UNIT'],
        },
      ]
      if (this.locations && this.locations.length) {
        for (let i = 0; i < this.locations.length; i++) {
          headerTemp.push({
            editable: true,
            field: `${this.locations[i].id}-${this.locations[i].name}-${i}`,
            label: [this.locations[i].name, '', '', ''],
            idLocation: this.locations[i].id,
            placeholder: ['enter_location', 'enter_group', 'enter_item', 'enter_unit'],
            type: 'number',
          })
        }
      }
      this.columns = headerTemp
      let filteredData = []
      filteredData[0] = ['2020-01-04 00:00:00']
      for (let i = 0; i < this.locations.length; i++) {
        filteredData[0].push(`${i + 2}`)
      }
      filteredData[1] = ['2020-01-05 00:00:00']
      for (let i = 0; i < this.locations.length; i++) {
        filteredData[1].push(null)
      }

      this.rows = _.cloneDeep(filteredData)
      this.itemLength = this.rows.length
      this.filteredData = _.cloneDeep(filteredData)
    },
    blurInput(event, location) {
      try {
        let input = event.target
        input.setAttribute('disabled', true)
        this.$store.commit('mapBasedTimeseriesLoader/UPDATE_LOCALTION', {
          id: location.id,
          data: {
            name: input.value,
          },
        })
      } catch {}
    },
    clInput(event) {
      try {
        let input = event.target
        input.removeAttribute('disabled')
        input.focus()
      } catch {}
    },
    open() {
      this.$refs.filesImport.show()
    },
    close() {
      this.$refs.filesImport.hide()
    },
    openFile() {
      this.$refs.file.click()
    },
    filtered(dataFilter, searchTerm) {
      let filtered = dataFilter.filter((row) => {
        let valid = false
        for (const key in row) {
          let value = row[key] === null ? 'NA' : row[key].toString()
          if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
            valid = true
            break
          }
        }
        return valid
      })
      return filtered
    },
    getDataTable(searchTerm, from, to, isGetDataToSearch) {
      if (searchTerm === '') {
        this.itemFilterLength = this.itemLength
        this.filteredData = this.filtered(this.rows.slice(+from, +to), searchTerm)
      } else {
        if (isGetDataToSearch) this.dataFilterStore = this.filtered([...this.rows], searchTerm)
        if (!this.dataFilterStore.length) {
          from = from === -1 ? 0 : from
          to = to === 0 ? 20 : to
          this.itemFilterLength = this.itemLength
          this.filteredData = this.filtered(this.rows.slice(+from, +to), '')
        } else {
          this.itemFilterLength = this.dataFilterStore.length
          this.filteredData = this.dataFilterStore.slice(+from, +to)
        }
      }
    },
    resetState() {
      this.files = []
      this.columns = []
      this.rows = []
      this.filename = ''
    },
    addFile() {
      let file = this.$refs.file.files[0]
      Papa.parse(file, {
        skipEmptyLines: true,
        encoding: 'Shift-JIS',
        complete: (result) => {
          let csv = result.data
          let header = csv.filter((row) => ['ID', 'GROUP', 'ITEM', 'UNIT'].includes(row[0]))

          let body = csv.filter((row) => dayjs(row[0]).isValid())

          if (body.length) {
            let rowLength = body[0].length
            if (!header.some((row) => row[0] === 'ID')) {
              header.unshift(['ID', ...Array(rowLength - 1).fill('')])
            }
            if (!header.some((row) => row[0] === 'GROUP')) {
              header.push(['GROUP', ...Array(rowLength - 1).fill('')])
            }
            if (!header.some((row) => row[0] === 'UNIT')) {
              header.push(['UNIT', ...Array(rowLength - 1).fill('')])
            }
            if (!header.some((row) => row[0] === 'ITEM')) {
              header.splice(1, 0, ['ITEM', ...Array(rowLength - 1).fill('')])
            }

            body = body.map((row) => row.map((cell, index) => (index ? convertNumber(cell) : dayjs(cell).format('YYYY-MM-DD HH:mm:ss'))))
            this.files = [...this.files, { id: uuidv4(), name: file.name, header, body, variant: 'success' }]
          }

          this.$refs.file.value = ''
        },
      })
    },
    deleteFile(id) {
      this.files = this.files.filter((f) => f.id !== id)
    },
    editHeader({ field, index, value }) {
      let colIndex = this.columns.findIndex((col) => col.field === field)
      if (colIndex !== -1) {
        let newCol = _.cloneDeep(this.columns[colIndex])
        newCol.label[index] = value.trim()
        this.columns.splice(colIndex, 1, newCol)

        let dataTemp = {}
        try {
          for (let i = 0; i < this.locations.length; i++) {
            dataTemp[this.locations[i].id] = []
          }
          for (let i = 0; i < this.columns.length; i++) {
            if (dataTemp[this.columns[i].idLocation]) {
              dataTemp[this.columns[i].idLocation].push(this.columns[i])
            }
          }
        } catch {}
        try {
          for (let key in dataTemp) {
            if (this.$mapBasedTimeseriesLoader[key]) {
              if (dataTemp[key] && dataTemp[key].length) {
                this.$mapBasedTimeseriesLoader[key].header = dataTemp[key]
              } else {
                this.$mapBasedTimeseriesLoader[key].header = null
              }
            }
          }
        } catch {}
      }
    },
    async save() {
      // Convert array of row object to 1 datasource object and add to store
      let id = uuidv4()
      let name = this.filename + (this.filename.endsWith('.csv') ? '' : '.csv')
      let type = 'timeseries'
      let dates = this.rows.map((r) => r.date)
      let data = {}
      let autoGroups = {}
      let datasourceData = []
      let datasourceColumns = {}
      let tmpGroup = null // temp variable
      const GROUP_IDX = this.columns[0].label.findIndex((c) => c === 'GROUP')
      const ITEM_IDX = this.columns[0].label.findIndex((c) => c === 'ITEM')
      const UNIT_IDX = this.columns[0].label.findIndex((c) => c === 'UNIT')
      // Các cột ngoại trừ cột date
      let columns = this.columns.map((c) => c.field).filter((c) => c !== 'date')
      columns.forEach((column, index) => {
        // TODO: real calculate 'nullCount'
        let tmpName = this.columns[index + 1].label[0] + '*' + this.columns[index + 1].label[ITEM_IDX]
        if (Object.keys(datasourceColumns).includes(tmpName)) tmpName = `${this.columns[index + 1].label[0]}_1*${this.columns[index + 1].label[ITEM_IDX]}`
        datasourceColumns[tmpName] = { nullCount: 0, autoGroup: this.columns[index + 1].label[GROUP_IDX], unit: this.columns[index + 1].label[UNIT_IDX] }
        // Build autoGroup
        tmpGroup = GROUP_IDX !== -1 ? this.getGroupName(this.columns[index + 1].label[GROUP_IDX]) : []
        tmpGroup.map((grName) => {
          if (!grName) return
          if (!autoGroups[grName]) autoGroups[grName] = []
          autoGroups[grName].push(tmpName)
        })
      })
      // Build data
      this.rows.forEach((row) => {
        let rowData = []
        rowData.push(row.date)
        columns.forEach((column, index) => {
          rowData.push(row[column])
        })
        datasourceData.push(rowData)
      })

      //Build data
      const keysCol = [...Object.keys(datasourceColumns)]
      for (let i = 0; i < keysCol.length; i++) {
        for (let j = 0; j < datasourceData.length; j++) {
          if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = []
          data[keysCol[i].replace('*', '-')].push(datasourceData[j][i + 1])
        }
      }
      // Build Items
      let items = {}
      let itemArray = [...new Set(this.columns.slice(1).map((column) => column.label[ITEM_IDX]))]
      itemArray.forEach((item) => {
        if (!item) return
        let obj = { min: Infinity, max: -Infinity, unit: '' }
        // Min & max
        for (const key in data) {
          if (key.endsWith(`-${item}`)) {
            for (let i = 0; i < data[key].length; i++) {
              if (data[key][i] !== null && data[key][i] < obj.min) obj.min = data[key][i]
              if (data[key][i] !== null && data[key][i] > obj.max) obj.max = data[key][i]
            }
          }
        }
        // Unit
        let index = this.columns.findIndex((column) => column.label[ITEM_IDX] === item)
        obj.unit = this.columns[index].label[UNIT_IDX]
        items[item] = obj
      })

      // Build Locations
      let locations = {}
      let locationArray = [...new Set(this.columns.slice(1).map((column) => column.label[0]))]
      locationArray.forEach((location) => {
        if (!location) return
        let obj = {}
        locations[location] = obj
      })

      let datasourceObject = { id, name, type, storage_mode: 'RAM', columns: datasourceColumns, dates, locations, items, csvData: datasourceData, data, header: [...this.columns[0].label], autoGroups }
      await this.storeData(datasourceObject)
      const file = await this.createObjectFile(datasourceObject)
      datasourceObject.file = file
      datasourceObject.size = file.size
      await this.addDatasource(datasourceObject)
      this.updateDatatable++
      let messageTitle = 'create_from_merge'
      let message = this.$t(messageTitle, { move: name })
      this.log.info({ message, id: ACTION_LOG[7].id, messageTitle, move: name })
      // this.log.info({ message: `Create and add datasource '${name}' from datasource tool [Merge]`, id: ACTION_LOG[7].id });
      // this.close();
    },
    getGroupName(str) {
      return str.toString().split('|')
    },
    mergeDateArrays(datasets) {
      // 1. Gộp tất cả các mảng ngày lại và loại bỏ trùng lặp
      let allDates = []
      datasets.forEach((dataset) => {
        allDates = allDates.concat(dataset[0])
      })
      allDates = [...new Set(allDates)]

      // 2. Sắp xếp lại các ngày
      allDates.sort()

      // 3. Tạo mảng kết quả với mảng ngày
      const result = [allDates]

      // 4. Duyệt qua từng dataset để gán giá trị vào mảng kết quả
      datasets.forEach((dataset) => {
        const [dates, ...values] = dataset
        const numValues = values.length

        // Với mỗi mảng giá trị, tạo một đối tượng để ánh xạ ngày tới dữ liệu
        const valueMaps = values.map((valueArray) => {
          const valueMap = {}
          dates.forEach((date, index) => {
            valueMap[date] = valueArray[index]
          })
          return valueMap
        })

        // Tạo các mảng dữ liệu đã gộp cho mỗi mảng giá trị
        for (let i = 0; i < numValues; i++) {
          const mergedData = allDates.map((date) => (date in valueMaps[i] ? valueMaps[i][date] : null))
          result.push(mergedData)
        }
      })

      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.mbtsl-files-import-content {
  max-height: calc(100vh - 310px);
  // overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }

  &-left {
    width: 240px;
  }

  &-right {
    flex: 1;
    margin-left: 1rem;
    height: calc(100vh - 330px);
  }
}

.mbtsl-header {
  font-size: 18px;
}

.locations-item {
  margin: 5px 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 36px;
  background: rgba(57, 123, 255, 0.12);

  &-icon {
    width: 36px;
    height: 36px;
    background-color: rgba(57, 123, 255, 0.12);
    color: #397bff;
    border-radius: 4px;
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
    }
  }

  &-name {
    flex: 1;
    padding: 8px 4px;
  }

  &-action {
    margin-right: 5px;
    display: flex;
    align-items: center;

    &-item {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: rgba(57, 123, 255, 0.12) !important;
      color: #397bff !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.tool-custom-radio label {
  line-height: 14px !important;
  padding: 6px 14px !important;
}

.tool-custom-label {
  display: block;
  line-height: 10px;
  margin-bottom: 3px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}

.files-import-modal .file-manager {
  width: 255px;
  flex-shrink: 0;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 310px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}

.files-import-modal .file-container {
  height: calc(100vh - 255px);
  overflow-y: scroll;
}

.files-import-modal .file-name {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
  margin-bottom: 0;
}

.files-import-modal .file-icon {
  cursor: pointer;
  flex-shrink: 0;
}

.files-import-modal .file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.357rem;
  margin-bottom: 6px;
}

.files-import-modal .file-media {
  min-width: 0;
  flex-grow: 1;
  margin-right: 10px;
}

.files-import-modal .file-media .media-body {
  min-width: 0;
}

.files-import-modal .file-media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ip-location-name {
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

input.ip-location-name[disabled] {
  cursor: default !important;
}
</style>
