<template>
  <div class="layer-container" @contextmenu="$emit('contextmenu', $event)">
    <div class="layer-item" ref="layerContainer">
      <span :title="$t('move')" class="d-flex align-items-center"><feather-icon icon="FolderIcon" style="margin: 0 6px 0 1px; min-width: 14px" class="handle" /></span>
      <!-- <component :is="require('/static/images/layer-config/radio/layer-folder.svg').default" class="select-layer" :style="{ width: '14px', height: '14px', marginLeft: '5px', color: layerMove === group.id ? (layoutSkin === 'dark' ? '#ffffff' : '#000000') : 'inherit' }" /> -->
      <b-form-input placeholder="Layer Name" size="sm" :value="group.name" class="layer-name select-layer" :style="{ color: layerMove === group.id ? (layoutSkin === 'dark' ? '#ffffff' : '#000000') : 'inherit' }" @click.stop @blur="updateName($event.target.value)" />
      <span :title="collapse ? $t('collapse_folder') : $t('expand_folder')" class="d-flex align-items-center"><feather-icon :icon="collapse ? 'MinusSquareIcon' : 'PlusSquareIcon'" style="cursor: pointer; margin-left: 5px; min-width: 14px" @click.stop="collapseItem" /></span>
      <span :title="!visible ? this.$t('show_layer') : this.$t('hide_layer')" class="d-flex align-items-center"><feather-icon :icon="visible ? 'EyeIcon' : 'EyeOffIcon'" style="cursor: pointer; margin-left: 5px; min-width: 14px" @click.stop="visibleLayer" /></span>
      <span :title="$t('delete')" class="d-flex align-items-center"><feather-icon icon="Trash2Icon" class="text-danger" style="cursor: pointer; margin-left: 5px; min-width: 14px" @click.stop="openGroupItemDeleteConfirm" /></span>
      <GroupItemDeleteConfirm ref="groupItemDeleteConfirm" :group="group" />
    </div>
  </div>
</template>

<script>
const _ = require('lodash');
import GroupItemDeleteConfirm from './tools/GroupItemDeleteConfirm.vue';
import { ACTION_LOG } from '@/constants/actionLog';
export default {
  components: {
    GroupItemDeleteConfirm,
  },
  props: ['group', 'layerMove'],
  data() {
    return {
      collapse: true,
      visible: true,
      oldName: this.group.name,
      showContextmenuLayer: false,
      styleContextMenu: '',
      disablePaste: false,
    };
  },
  computed: {
    groups() {
      return this.$store.state.layer.groups;
    },
    layoutSkin() {
      return this.$store.state.appConfig.layout.skin;
    },
    contextMenuCopy() {
      return this.$store.state.map.contextMenu.layerCopy;
    },
  },
  methods: {
    collapseItem() {
      try {
        let groupEle = document.querySelector(`.sk-${this.group.id}`);
        let heightGroupEle = groupEle.getBoundingClientRect().height;
        heightGroupEle = !heightGroupEle || heightGroupEle <= 0 ? 30 : heightGroupEle;
        let heightItemSubGroupEle = groupEle.querySelector('.item-sub').getBoundingClientRect().height;
        heightItemSubGroupEle = !heightItemSubGroupEle || heightItemSubGroupEle <= 0 ? 0 : heightItemSubGroupEle;
        if (this.collapse) {
          groupEle.style.maxHeight = `${heightGroupEle - heightItemSubGroupEle}px`;
        } else {
          groupEle.style.maxHeight = `${heightGroupEle + heightItemSubGroupEle}px`;
          setTimeout(() => {
            groupEle.style.maxHeight = `100%`;
          }, 250);
        }
        this.collapse = !this.collapse;
      } catch {}
    },
    updateName(name) {
      this.$store.commit('layer/UPDATE_NAME_GROUPS', { id: this.group.id, name });
      if (this.oldName.trim().toLowerCase() != name.trim().toLowerCase()) {
        let messageTitle = 'rename_group';
        let messageLog = `: '${this.oldName}' -> '${name}'`;
        let message = this.$t(messageTitle) + messageLog;
        this.log.info({ message, id: ACTION_LOG[12].id, messageTitle, messageLog });
        this.oldName = name;
      }
    },

    openGroupItemDeleteConfirm() {
      this.$refs.groupItemDeleteConfirm.open();
    },
    visibleLayer() {
      this.visible = !this.visible;
      let grVisible = this.groups.find((gr) => gr.id === this.group.id);
      grVisible.groupData.map((child) => {
        child.visible[0] = this.visible ? true : false;
      });
      this.$store.commit('layer/SET_STATUS_LAYER_MOVE', true);
      this.$store.commit('layer/UPDATE_GROUP', grVisible);
      let messageTitle = this.visible ? 'show_group' : 'hiden_group';
      let messageLog = `: '${this.group.name}'`;
      let message = this.$t(messageTitle) + messageLog;
      this.log.info({ message, id: ACTION_LOG[14].id, messageTitle, messageLog });
    },
  },
};
</script>
<style lang="scss">
.layer-list {
  .item-group {
    transition: max-height ease-in-out 0.25s;
    overflow: hidden;
  }
}
</style>
