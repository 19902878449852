import * as SHAPES from '@/constants/shapes';
import { PALETTE_PICKER } from '@/constants/colors';
import i18n from '@/libs/i18n';

export const DEFAULT_LAYER = {
  id: null,
  version: 1,
  inspectCount: 0,
  type: 'point',
  name: i18n.t('new_layer'),
  visible: [true, true], // 1 layer have visible boolean for 2 map

  dataPoint: null,
  dataTimeseries: null,
  item: null,
  dataImage: null,
  dataTyphoon: null,
  dataMulti: null,
  dataTimeseriesShape: null,
  dataNetcdf: null,
  idMultiSelected: null,
  itemMultiSelected: null,
  shape: {
    shapeType: SHAPES.CIRCLE,
    numSpikes: 5,
  },
  fillOpacity: 0.75,
  fillColor: {
    mode: 'solid',
    color: '#397BFF',
    colors: PALETTE_PICKER[0],
    levels: [
      { id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d', label: 'Warning', color: '#011EFE' },
      { id: '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed', label: 'Risk', color: '#FE0000' },
    ],
    individualThreshold: false,
    thresholds: {
      General: ['', ''],
    },
  },
  color: '#FFFFFF',
  weight: 1,
  radius: { min: 5, max: 50 },
  tooltip: {
    visible: true,
    permanent: false,
    direction: 'top',
    family: 'arial',
    size: 16,
    color: '#000000',
    backgroundColor: '#FFFFFFB3',
    opacity: 1,
  },
  popup: {
    visible: true,
    multiShow: false,
    type: 'border',
    lineColor: '#397BFF', // borderless only
    backgroundColorFrom: '#FFFFFFFF ', // border only
    backgroundColorTo: '#FFFFFFFF', // border only
    opacity: 0.8, // border only
    family: 'arial',
    headerColor: '#000000',
    headerSize: 14,
    contentColor: '#000000',
    headerColorBorderless: '#2c3b42',
    contentColorBorderless: '#2c3b42',
    contentSize: 12,
  },
  legend: {
    visible: false,
    shapeVisible: false,
    displayLayerName: true,
    displayItem: true,
    displayUnit: true,
    barWidth: 12,
    shapeSizeFixed: false,
    position: {
      x: 0,
      y: 0,
    },
    autoUpdate: true,
  },
  navalue: {
    visible: true,
    fillOpacity: 0.5,
    fillColor: '#4a4a4a',
    color: '#9b9b9b',
    weight: 1,
    radius: 5,
  },

  image: {
    opacity: 1,
    corners: null,
  },

  netcdf: {
    opacity: 1,
    colorMap: 'rainbow',
    netcdfOutlineWidth: 0,
    netcdfOutlineColor: '#ffffff',
    transparentMode: true,
    shadingMode: 'Gouraud',
    edgeWidth: 0.001,
    edgecolors: '#FFFFFF00',
    minMaxConfig: false,
    minValScale: 0,
    maxValScale: 50,
    showNaValue: false,
    showGrid: false,
    resample: 1,
    preRenderData: true,
    showRangeCache: true,
    unLimit: true,
    coordFunc: 'mean',
    visualizeMode: 'gradient',
    netcdfCache: [],
    vectorMode: false,
    drawVectorBackground: true,
  },

  typhoon: {
    icon: {
      radius: 5,
    },
    line: {
      weight: 2,
      color: '#000000',
    },
    storm: {
      weight: 1,
      color: '#397BFF',
    },
    gale: {
      weight: 1,
      color: '#00C853',
    },
    trail: {
      keep: false,
      opacity: 0.5,
    },
  },
  markerCluster: {
    using: true,
    showCoverageOnHover: true,
    zoomToBoundsOnClick: false,
    maxClusterRadius: 0,
  },
  isGroup: false,
  groupData: null,
  scale: {
    visible: true,
    value: null,
  },
  pin: true,
  updateStyle: 0,
  firstCreate: true,

  processRate:false,
  processRateData:{
    segment:100,
    progressValue:null,
    progressMethod:'progress-current',
    polygonValue:null,
    geometryType:'polyline',
  }
};
export const DEFAULT_GROUP = {
  id: null,
  name: i18n.t('new_group'),
  isGroup: false,
  groupData: [],
  version: 1,
};
